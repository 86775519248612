<template>
  <div class="static-page">

    <section class="static-page__hero">
      <div class="static-page__hero-backdrop">
        <img class="static-page__hero-image" data-image="prenota-un-viaggio" src="assets/images/booking/statics/prenota-un-viaggio-hero.jpg" alt="Prenota un viaggio" />
      </div>
    </section>

    <section class="section">
      <b-container>
        <h1 v-html="config.site.brand + ' <strong class=\'semibold text-primary\'>' + config.site.payoff + '</strong>'"/><br/>
        <p v-html="'Su questo sito puoi prenotare le proposte di viaggio di uno degli operatori leader del travel online globale. <br> Oltre un milione di strutture in tutto il mondo, grandi catene alberghiere, hotel indipendenti, un’infinità di bed&breakfast, appartamenti e case, villaggi, resort e glamping.'" />
        <p v-html="'E’ tutto a portata di pochi click e prenotabile <strong class=\'semibold\'>con il tuo credito loyalty o con il tuo welfare aziendale.</strong>'" /><br>
        <p v-html="'Prenotando le offerte di Booking.com su VadoBay beneficerai del nostro <strong class=\'semibold\'>servizio a valore aggiunto</strong>'" />

        <ul>
          <li v-html="'assistenza diretta e multicanale al telefono, in webchat e su WhatsApp curata dal nostro team di customer care interno'" />
          <li v-html="'un team di assistenza esperto nel gestire le specifiche esigenze dell’utenza loyalty e welfare'" />
          <li v-html="'un servizio dedicato che verifica ogni prenotazione prima del tuo arrivo a destinazione'" />
          <li v-html="'un algoritmo che seleziona le migliori strutture in base a qualità, esperienza e professionalità'" />
        </ul>

      </b-container>
    </section>

    <section class="section">
      <b-container>
        <value-proposition
          :vp="[
            {
              vp_image: 'assets/images/booking/statics/prenota-un-viaggio.jpg',
              vp_content: [
                {
                  vp_icon: '',
                  vp_title: '<h2 class=\'vb-heading\'>Come prenotare la tua soluzione di viaggio</h2>',
                  vp_text:
                    '<p>La cosa più importante che devi verificare prima di prenotare è che la tariffa/soluzione che hai scelto sia adeguata per il numero e la tipologia di partecipanti al viaggio</p><br>' +
                    '<h3 class=\'h4\'><span class=\'text-secondary\'>Come selezionare</span> la tariffa/soluzione adeguata?</h3>' +
                    '<p>Per ogni tariffa/soluzione trovi delle <strong>icone che indicano il numero di adulti e il numero di bambini</strong> che possono essere ospitati. Passandoci sopra col mouse trovi anche informazioni specifiche sull’età dei bambini.</p><br>' +
                    '<h3 class=\'h4\'>Come <span class=\'text-secondary\'>essere sicuro</span> di avere selezionato una tariffa/soluzione corretta?</h3>' +
                    '<p>Se hai selezionato una tariffa/soluzione non adeguata al numero di viaggiatori che hai cercato, troverai nella pagina di checkout, <strong class=\'semibold\'>un messaggio di allerta di colore rosso</strong> e l’invito a modificare la tua selezione.</p>'
                }
              ]
            }
          ]"
      />
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <h3 class="vb-heading" v-html="'Errori di prenotazione'" /><br/>
        <p v-html="'Attenzione: anche nel caso in cui tariffa/soluzione che hai selezionato non sia adeguata ai viaggiatori che hai cercato la prenotazione andrebbe a buon fine ma è possibile che ti venga poi richiesto di pagare un <strong class=\'semibold\'>supplemento al momento del checkin</strong> in struttura.'" />
        <p v-html="'Se, invece, tentassi di prenotare una soluzione/tariffa con un numero di adulti inferiore al numero di adulti che hai cercato, allora, in quel caso, otterresti un errore di prenotazione e ti consigliamo di verificare che il credito per il pagamento non sia stato addebitato. In caso di problemi contattaci.'" />
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <h3 class="vb-heading" v-html="'Culle e letti <span class=\'text-secondary\'>aggiuntivi</span>'" /><br/>
        <p v-html="'Nella pagina dell’hotel troverai indicato se l’hotel dispone di culle, letti aggiuntivi e il loro costo. Culle e letti aggiuntivi <strong class=\'semibold\'>non sono prenotabili online</strong> sul sito ma vanno prenotati direttamente con l’hotel e pagati in loco. <strong class=\'semibold\'>Contattaci</strong> prima della prenotazione per eventuali verifiche.'" />
      </b-container>
    </section>

    <section class="section">
      <div class="static-page__form">
        <b-container>
          <form-contatti
              :formTitle="title_request_form"
              :formSubTitle="subtitle_request_form"
              :label="'form-contatti-prenota-un-viaggio'"
              :recipient="site.emailSupport"
          />
      </b-container>
      </div>
    </section>

  </div>
</template>


<script>
import Vue from "vue";
import FormContatti from '@components/blocks/form-contatti.vue'
import ValueProposition from '@library/blocks/value-proposition.vue'

export default {
  name: 'prenota-un-viaggio',
  components:{
    'form-contatti': FormContatti,
    'value-proposition': ValueProposition,
  },
  props: {
  },
  created() {
  },
  data () {
    return {
      config: Vue.prototype.$config,
      site: Vue.prototype.$config.site,
      title_request_form: 'Hai bisogno di <span class=\'text-secondary\'>maggiori informazioni?</span> Contattaci!',
      subtitle_request_form: 'Compila il seguente form oppure scrivi una mail a <a href=\'mailto:' + Vue.prototype.$config.site.emailSupport + '\'>' + Vue.prototype.$config.site.emailSupport + '</a>',
    }
  },
  computed: {
  },
}
</script>


<style lang="scss" scoped>

  .static-page {

    &__hero {
      &-image {
        @include media-breakpoint-up(xl) {
          top: 35%;
        }
      }
    }
  }

</style>