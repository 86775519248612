var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"static-page"},[_vm._m(0),_c('section',{staticClass:"section"},[_c('b-container',[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.config.site.brand + ' <strong class=\'semibold text-primary\'>' + _vm.config.site.payoff + '</strong>')}}),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s('Su questo sito puoi prenotare le proposte di viaggio di uno degli operatori leader del travel online globale. <br> Oltre un milione di strutture in tutto il mondo, grandi catene alberghiere, hotel indipendenti, un’infinità di bed&breakfast, appartamenti e case, villaggi, resort e glamping.')}}),_c('p',{domProps:{"innerHTML":_vm._s('E’ tutto a portata di pochi click e prenotabile <strong class=\'semibold\'>con il tuo credito loyalty o con il tuo welfare aziendale.</strong>')}}),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s('Prenotando le offerte di Booking.com su VadoBay beneficerai del nostro <strong class=\'semibold\'>servizio a valore aggiunto</strong>')}}),_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s('assistenza diretta e multicanale al telefono, in webchat e su WhatsApp curata dal nostro team di customer care interno')}}),_c('li',{domProps:{"innerHTML":_vm._s('un team di assistenza esperto nel gestire le specifiche esigenze dell’utenza loyalty e welfare')}}),_c('li',{domProps:{"innerHTML":_vm._s('un servizio dedicato che verifica ogni prenotazione prima del tuo arrivo a destinazione')}}),_c('li',{domProps:{"innerHTML":_vm._s('un algoritmo che seleziona le migliori strutture in base a qualità, esperienza e professionalità')}})])])],1),_c('section',{staticClass:"section"},[_c('b-container',[_c('value-proposition',{attrs:{"vp":[
          {
            vp_image: 'assets/images/booking/statics/prenota-un-viaggio.jpg',
            vp_content: [
              {
                vp_icon: '',
                vp_title: '<h2 class=\'vb-heading\'>Come prenotare la tua soluzione di viaggio</h2>',
                vp_text:
                  '<p>La cosa più importante che devi verificare prima di prenotare è che la tariffa/soluzione che hai scelto sia adeguata per il numero e la tipologia di partecipanti al viaggio</p><br>' +
                  '<h3 class=\'h4\'><span class=\'text-secondary\'>Come selezionare</span> la tariffa/soluzione adeguata?</h3>' +
                  '<p>Per ogni tariffa/soluzione trovi delle <strong>icone che indicano il numero di adulti e il numero di bambini</strong> che possono essere ospitati. Passandoci sopra col mouse trovi anche informazioni specifiche sull’età dei bambini.</p><br>' +
                  '<h3 class=\'h4\'>Come <span class=\'text-secondary\'>essere sicuro</span> di avere selezionato una tariffa/soluzione corretta?</h3>' +
                  '<p>Se hai selezionato una tariffa/soluzione non adeguata al numero di viaggiatori che hai cercato, troverai nella pagina di checkout, <strong class=\'semibold\'>un messaggio di allerta di colore rosso</strong> e l’invito a modificare la tua selezione.</p>'
              }
            ]
          }
        ]}})],1)],1),_c('section',{staticClass:"section"},[_c('b-container',[_c('h3',{staticClass:"vb-heading",domProps:{"innerHTML":_vm._s('Errori di prenotazione')}}),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s('Attenzione: anche nel caso in cui tariffa/soluzione che hai selezionato non sia adeguata ai viaggiatori che hai cercato la prenotazione andrebbe a buon fine ma è possibile che ti venga poi richiesto di pagare un <strong class=\'semibold\'>supplemento al momento del checkin</strong> in struttura.')}}),_c('p',{domProps:{"innerHTML":_vm._s('Se, invece, tentassi di prenotare una soluzione/tariffa con un numero di adulti inferiore al numero di adulti che hai cercato, allora, in quel caso, otterresti un errore di prenotazione e ti consigliamo di verificare che il credito per il pagamento non sia stato addebitato. In caso di problemi contattaci.')}})])],1),_c('section',{staticClass:"section"},[_c('b-container',[_c('h3',{staticClass:"vb-heading",domProps:{"innerHTML":_vm._s('Culle e letti <span class=\'text-secondary\'>aggiuntivi</span>')}}),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s('Nella pagina dell’hotel troverai indicato se l’hotel dispone di culle, letti aggiuntivi e il loro costo. Culle e letti aggiuntivi <strong class=\'semibold\'>non sono prenotabili online</strong> sul sito ma vanno prenotati direttamente con l’hotel e pagati in loco. <strong class=\'semibold\'>Contattaci</strong> prima della prenotazione per eventuali verifiche.')}})])],1),_c('section',{staticClass:"section"},[_c('div',{staticClass:"static-page__form"},[_c('b-container',[_c('form-contatti',{attrs:{"formTitle":_vm.title_request_form,"formSubTitle":_vm.subtitle_request_form,"label":'form-contatti-prenota-un-viaggio',"recipient":_vm.site.emailSupport}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"static-page__hero"},[_c('div',{staticClass:"static-page__hero-backdrop"},[_c('img',{staticClass:"static-page__hero-image",attrs:{"data-image":"prenota-un-viaggio","src":"assets/images/booking/statics/prenota-un-viaggio-hero.jpg","alt":"Prenota un viaggio"}})])])
}]

export { render, staticRenderFns }